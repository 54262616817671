export const Translation = {
  present: "Present Analysis",
  Operation: "Operation Analysis",
  Space: "Space Analysis",
  CommuteSimulation: "Commute Simulation",
  RoomSimulation: "Room Simulation",
  CostSimulation: "Cost Simulation",
  summarySetting: "Summary Setting",
  Simulation: "Simulation",
  survey: "Survey",
  answer_survey: "Answer Survey",
  workshopInterview: "Workshop/Interview",
  summary: "Summary",
  officeExample: "Office Example",
  userSetting: "User Setting",
  adminSetting: "Admin Setting",
  manageSurvey: "Manage Survey",
  listSurvey: "Survey List",
  addSurvey: "Add Survey",
  surveyTitle: "Survey Title",
  action: "Action",
  edit: "Edit",
  delete: "Delete",
  surveys: "Surveys",
  createJsonTextCopy: "  to create a survey, copy the JSON from JSON Editor and paste below. ",
  editJsonTextXCopy: "  to edit a survey, copy the JSON from JSON Editor and paste below.",
  link: "link",
  andPasteBelow: "  and paste it in survey textarea below",
  update: "Update",
  placeYourJsonData: "Place your JSON data.",
  createSurvey: "Create Survey",
  editSurvey: "Edit Survey",
  takeSurvey: "Take Survey",
  viewOldSurvey: "View Old Survey",
  followThis: "Follow this ",
  submit: "Submit",
  save: "Save",
  dropYourImage: "Select the Files",
  downloadSampleCsv: "Download Sample Csv",
  dragfiles: "Drag Your Files",
  uploadyourFilesText: "Upload Your Files",
  dragyourFileText: "Drag Your Files",
  source_select: "Source Select",
  username: "Username",
  password: "Password",
  password_updated: "Password Updated",
  password_mismatched: "Password Mismatched",
  incorrect_password: "Incorrect Password",
  new_password: "New Password",
  cnf_password: "Confirm Password",
  current_password: "Current Password",
  click_here: " Click Here ",
  keep_me_sign: "Keep Me Sign In",
  create_work_for_future: "Create Work For Future.",
  creat_for_future_japan: "未来の「はたらく」を創造する。",
  fgt_pwd: "Forgot Password? ",
  login: "Login",
  keep_signed_in: "Keep Me Signed In",
  tenant: "Tenant",
  add: "Add",
  add_new_user: "add new user",
  user_email: "User Email",
  register: "Register",
  tenantname: "Tenant Name",
  usertype: "User Type",
  user_registration: "User Registration",
  admin_comments: "",
  office_address: "Current Office Address",
  new_office_address: "New Office Address",
  enter_new_office_address: "Enter New Office Address",
  user: "User",
  admin: "Admin",
  utilization_info: "Utilization Info",
  utilization_map: "Utilization Map",
  attendance_info: "Attendance Info",
  employee_info: "Employee Info",
  branch_info: "Branch Info",
  data_analysis: "Data Analysis",
  data_upload: "Data Upload",
  office_sample: "Office Sample",
  analysis_operation: "Analyze employee attendance, office stays, and seat usage",
  analysis_space: "Visualize space usage by floor",
  commute_simulation_title: "Visualize where employees live and simulate commuting time and one-way fares",
  room_simulation_title: "Simulate the image of space usage according to the type of work style",
  cost_simulation_title: "Simulate the amount and per capita cost information",
  cost_simulation: "Cost Simulation",
  survey_results_title: "Analyze and visualize survey responses",
  surveyNumberofRespondents: "Number of Respondents",
  surveyResponseRate: "Response Rate",
  place_style_survey: "Place Style Survey",
  close: "Close",
  save_tenant: "Save Tenant",
  add_tenant: "Add Tenant",
  author: "Author",
  reader: "Reader",
  please_wait_for_tenant_response: "Please wait while the tenant is being provisioned",
  user_is_required: "User Name is required.",
  tenant_is_required: "Tenant type is required.",
  type_is_required: "Type is required",
  email_is_required: "Email is required",
  not_proper_email: "Not a proper email",
  edit_address: "Edit Address",
  save_address: "Save Address",
  add_new_tenant_name: "Add New Tenant Name",
  floor_select: "Select Floor",
  analysis: "Analysis",
  survey_setting: "Survey Setting",
  top_interview_setting: "Top Interview Setting",
  workshop_setting: "Workshop Setting",
  administration_menu: "Administration menu",
  workshop_survey: "Workshop & Survey",
  workshop: "Workshop",
  top_interview: "Top Interview",
  survey_response: "Survey Response",
  client_management: "Client User",
  top_interview_manage: "Top Interview Management",
  consultant_management: "Consultant Management",
  client_company: "Client Company",
  client_integration: "Client Integration",
  add_new_consultant: "Add New Consultant",
  add_new_client_integration: "Add New Client Integration ",
  division: "Division",
  name: "Name",
  client_in_charge: "Client in charge",
  consultant_in_charge: "Consultant in charge",
  account_info: "Account information",
  email: "Email",
  email1: "email 1",
  email2: "email 2",
  email3: "email 3",
  email4: "email 4",
  email5: "email 5",
  email6: "email 6",
  email7: "email 7",
  email8: "email 8",
  email9: "email 9",
  email10: "email 10",
  cancel: "Cancel",
  name_is_required: "Name is required",
  division_is_required: "Division is required",
  client_in_charge_is_required: "Client in charge is required",
  add_new_tenant: "Add New Tenant",
  company_name: "Company Name",
  address_changed: "Address changed. Refresh the screen after a while to make sure the data is reflected.",
  comments_submitted_successfuly: "Admin comments submitted successfully.",
  select_tenant: "Search Tenant",
  tenantId: "Tenant ID",
  industry: "Industry",
  scale: "Scale",
  address: "Address",
  tsubo: "tsubo",
  tenant_isrequired: "Tenant id is required",
  company_name_is_required: "Company name is required",
  industry_is_required: "Industry is required",
  scale_is_requied: "Scale is required",
  plan_is_required: "Plan is required",
  contractRange_is_required: "Contract Range is required",
  contractRange_err_required: "You cannot register any more accounts with your current contract plan.\n Plan：{0}",
  start_at_is_required: "Start At is required",
  expire_at_is_required: "Expire At is required",
  address_is_required: "Address is required",
  tsubo_is_required: "Tsubo is  required",
  client_user: "Client User",
  tenant_name_is_required: "Tenant Name is required",
  clients_is_required: "Clients is required",
  user_name: "User Name",
  number_is_required: "Please input a number",
  usertype_is_required: "User Type is required",
  locale_is_required: "Locale is required",
  locale: "Locale",
  workshop_results: "Workshop Results",
  top_interview_results: "Top Interview Results",
  edit_consultant: "Edit Consultant",
  edit_client_integration: "Edit Client Integration",
  contractPlan: "Client Integration Plan",
  contractPlanStartDate: "Start Date",
  contractPlanStartDate_is_required: "Start Date is required ",
  contractPlanEndDate: "End Date",
  contractPlanEndDate_is_required: "End Date is required",
  start_endDate_is_required: "Please make the end date and time later than the start date and time.",
  clientIntegration: "Client Integration",
  input_by_consultant: "Input form by consultants.",
  consultants: "Consultants",
  select: "Select",
  select_option: " -- Select -- ",
  floor: "Floor",
  select_a_response: "Select a response from the dropdown",
  by: "by",
  on: "on",
  new_tenant_request_registered:
    "The request to setup a new tenant has been registered successfully. Please wait until the tenant name appears in the list in the upper right-hand corner of the screen.",
  welcome: "Welcome to WDP",
  must_change_password: "You must change your password on first login.",
  change_password: "Change Password",
  current_password: "Current Password",
  new_password: "New Password",
  confirm_new_password: "Confirm New Password",
  upload_csv: "Upload CSV",
  import_usr_csv: "Import users from CSV",
  top_interview_list: "Top Interview List",
  top_interview_theme: "Top Interview Theme",
  workshop_list: "Workshop List",
  workshop_title: "Workshop Title",
  conduct_interview: "Conduct Interview",
  conduct_workshop: "Conduct Workshop",
  reset_your_pwd: "Reset your Password",
  survey_analysis: "Survey Analysis",
  result_csv_download: "CSV Download(survy result)",
  status_csv_download: "CSV Download(anwser status)",
  create_Workshop: "Create Workshop",
  create_topInterview: "Create Top Interview",
  edit_Workshop: "Edit Workshop",
  edit_topInterview: "Edit Top Interview",
  survey_manage: "Survey Management",
  workshop_manage: "Workshop Management",
  survey_response_list: "Survey Response List",
  survey_response_list_id: "Select survey response to export",
  user_already_exists: "User name already exists",
  tenants_refreshed: "Tenants refreshed successfully",
  send_verification: "Send Verification Email",
  enter_verification_received: "Enter your validation code",
  verification_code: "Verification Code",
  proceed: "Proceed",
  reset_success: "Password reset successfully",
  none_data_csv: "No data exists in the uploaded file. Please check the input contents and upload again.",
  invalid_csv: "Invalid CSV file",
  update_item_err: "Line {{line_number}} of data: {{item_name}} is required",
  update_item_duplicated_err: "{{index1}} line and {{index2}} line of data: Error due to duplicate email address registration Target location",
  email_in_use_err:  "Line {{line_number}} of data: Email has been used。{{email}}",
  user_type_content_err: "Line {{line_number}} of data: A user type other than CLIENT_LEADER or CLIENT_MEMBER has been specified.",
  tenant_content_err: "Line {{line_number}} of data: A client name (tenant_name) that is not registered has been specified.",
  email_content_err: "Line {{line_number}} of data:The format of the email address (email) is invalid.",
  table_title_err: "The title row of the first line in the table has been changed.",
  update_err_title: "An error occurred in the following item.",
  update_err_total: "Row Number of failures: {{errRowCount}}",
  csv_system_err: "An unexpected error occurred while uploading data. Please contact your administrator.",
  csv_request_submitted: "Import request submitted successfully. Please wait while all users are imported.",
  no_survyes: "No surveys",
  image_not_found: "Image not found",
  select_floor: "Select floor",
  user_deleted_successfully: "User deleted successfully",
  user_not_deleted: "User not deleted",
  are_u_Sure: "Are you sure?",
  select_usertype: "Select usertype",
  AccessLog: "Access Log",
  ItemsRequired: "Items Required",
  CorrespondingColumns: "Corresponding columns",
  Setting: "Setting",
  UserId: "user id",
  ColumnList: "Column list in csv",
  Datetime: "Datetime",
  SetJapaneseHoliday: "Set Japanese Holiday",
  TargetGate: "Target Gate",
  AllDataWillBeUsed: "All data will be used",
  TargetDateSetting: "Target Date and Slot Setting",
  Weekday: "Weekday",
  Slot: "Slot",
  Holiday: "Holiday",
  InputSetting: "Input Setting",
  CSVDataOfWSP: "CSV data of Wifi Access points",
  CSVDataOfAL: "CSV data of Access logs",
  ManuallyECD: "Manually entered CSV data",
  WifiAccessPoint: "Wifi access point",
  Secret: "Secret",
  endpoint: "endpoint",
  URL: "URL",
  WifiManual: "Wifi Manual",
  NoColumnsApplicable: "No columns applicable",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  operation_analysis_setting: "Operation Analysis Setting",
  inputSettingStep1: "Step1: Register the above values from the settings screen at the following URL",
  inputSettingStep2: "Step2: After registering, press the button below to complete the settings on the application",
  num_employee: "Num employee",
  rate_attendance: "Rate attendance",
  tsubo: "Tsubo",
  unit_price_tsubo_per_tsubo_monthly: "Unit price tsubo per tsubo monthly",
  unit_price_admin_expense_per_tsubo_monthly: "Unit price admin expense per tsubo monthly",
  contract_months: "Contract months",
  initial_work_months: "Initial work months",
  seating_capacity: "Seating capacity",
  interior_construction_cost_per_tsubo: "Interior construction cost per tsubo",
  interior_furniture_fee_per_tsubo: "Interior furniture fee per tsubo",
  insitu_restoration_cost_per_tsubo: "Insitu restoration cost per tsubo",
  electricity_and_utilities_per_tsubo_montly: "Electricity and utilities per tsubo montly",
  seat_occupancy_rate: "Seat occupancy rate",
  clk_here: "Click here",
  send: "Send",
  email_verified: "Enter your email address",
  back_login: "Back to login",
  top_page: "Top Page",
  management: "Management",
  account_management: "Account Management",
  research_management: "Research Management",
  as_is: "As-Is",
  answer_survey: "Answer Survey",
  qualitative_research: "Qualitative Reserach",
  space_simulation: "Space Simulation",
  commute_simulation: "Commute Simulation",
  comparative_review: "Comparative Review",
  add_agent: "Add New Agent",
  edit_agent: "Edit Agent",
  agent_name: "Agent Name",
  person_char: "Person In Charge",
  num_consultants: "Number of consultants",
  client: "Clients",
  num_clients: "Number of clients",
  plan: "Plan",
  billing: "Billing Method",
  agent: "Agent",
  select_file: "Select File",
  drag_drop: "Please Drag and Drop a file",
  num_of_acc: "Number of Accounts",
  logo_mark: "Logo Mark",
  status: "Initial Login",
  process: "Process",
  duplication: "Duplication",
  set_new_password: "Set New Password",
  csv_upload: "CSV upload",
  interviews: "Interviews",
  workshops: "Workshops",
  search_data: "Search by data",
  room_analysis: "Room Analysis",
  updated_at: "Updated At",
  data: "Data",
  surveu_summary: "Place Style Survey",
  senarios: "Senarios",
  room_simulation: "Room Simulation",
  check_office_sample: "Check Office Sample",
  new_password: "New Password",
  admin_comment: "Admin Comment",
  submitted_surveys: "Submitted Survey",
  account_setting: "Account Setting",
  hello: "Hello",
  Description: "WorkPlace",
  floor_map: "Floor Map",
  select_floor: "Select Floor",
  save_pattern: "Save Pattern",
  pattern_name: "Pattern Name",
  mode: "WorkMode",
  num_private_room: "num private room",
  Simulate: "Simulate",
  Save: "Save Pattern",
  select_pattern: "Select Pattern",
  pattern: "Pattern",
  params: "Params",
  utilization_info: "utilization info",
  utilization_map: "utilization map",
  attendance_info: "attendance info",
  employee_info: "employee info",
  commute_info: "commute info",
  branch_info: "branch info",
  operation_analysis_setting: "operation analysis setting",
  completed: "completed",
  not_yet: "not yet",
  need_update: "need update",
  short_survey_summary: "Work Design Pulse Survey",
  deep_survey_summary: "Work Design Survey",
  select_param: "select",
  input_param: "Input",
  from_0_to_1: "0-1",
  total_work_point: "Work Design Score",
  respondent_ratio: "responce num",
  total_square_measure: "total square measure",
  total_tsubo: "total tsubo",
  num_seat_touch_point: "num seat touch point",
  num_seat_work: "num seat work",
  num_seat_meeting: "num seat meeting",
  rate_operation: "rate operation",
  num_attendance: "num attendance",
  commuting_time: "commuting time",
  transportation_fee: "transportation fee",
  comprehensive_evaluation: "comprehensive evaluation",
  workability_index: "workability index",
  improvement_index: "improvement index",
  cost_per_person: "cost per person",
  cost_per_seat: "cost per seat",
  cost_per_seat_substantial: "cost per seat substantial",
  work_mode: "work mode",
  num_employess: "num employess",
  total_area: "total area",
  area_per_person: "area per person",
  num_touch_point: "num touch point",
  num_seat_work: "num_seat_work",
  person: "",
  square_meters: "㎡",
  tsubo_measure: "tsubo",
  seat: "",
  percent: "％",
  minute: "min",
  yen: "Yen",
  point: "pt",
  date_and_time_zone_for_aggregation: "Date and time for aggregation",
  select_pattern_A: "シナリオ A",
  select_pattern_B: "シナリオ B",
  search: "Search",
  operation_plan: "Operational Plan",
  rental_plan: "Rental Plan",
  construction_plan: "Construction Plan",
  data_uploaded: "Completed",
  file_has_been_uploaded: "File has been uploaded.",
  upload_file: "Upload File",
  date_and_time_zone_of_aggregation: "Date and time of aggregation",
  Dayofweek: "Day of the Week",
  timeSlot: "Time Slot",
  nationalHolidays: "National Holiday",
  OperationalAnalysisSetting: "Operational Analysis Information",
  importData: "Import Data",
  csvData: "CSV Data from Wifi access point",
  excelData: "Excel data of access logs",
  manulaInput: "Manual input Excel data",
  automaticallyCollected: "Automatically collected from WiFi access points",
  downloadSample: "Download Sample",
  update: "Update",
  select_survey: "Select Survey",
  select_delivery_date: "Not delivered",
  first_week: "1st",
  second_week: "2nd",
  third_week: "3rd",
  fourth_week: "4th",
  last_week: "last",
  mon: "mon",
  tue: "tue",
  wed: "wed",
  thu: "thu",
  fri: "fri",
  sat: "sat",
  sun: "sun",
  survey_notification_setting: "Survey Notification Setting",
  monthly_interval: "monthly interval",
  start_at: "Start At",
  sendmail_at: "Send Email At",
  end_at: "End At",
  expire_at: "Expire At",
  deep_survey: "Work Design Survey",
  short_survey: "Work Design Pulse Survey",
  select_week: "Week",
  select_day_of_week: "Day_Of_Week",
  survey_results: "Survey Results",
  address_not_found: "Failed to specify the input address. Please change your input and try again.",
  remaining_time_expired: "API remaining time has been expired.",
  comparative_review_title: "compare and review all analysis results",
  upload: "Upload",
  logout: "LOGOUT",
  industry1: "industry1",
  industry2: "industry2",
  industry1_is_required: "industry1 is required",
  industry2_is_required: "industry2 is required",
  person_in_charge: "Person in charge",
  PIC_is_required: "Person in charge is required",
  apiRemainingTimes: "Area Simulation Remaining Times",
  consultant_name: "Consultant Name",
  start: "start",
  start_at_is_required: "start at is required",
  expire_at_is_required: "expire at is required",
  area_composition_ratio: "Area Composition Ratio",
  API_remaining_times_is_required: "API remaining time is required",
  valid_email_is_required: "valid email is required",
  survey_notification_setting_saved: "Survey Notification Setting has been saved",
  input_number: "input number",
  pleaseRegisterSettingURL: "Please register values settings from following URL",
  pleaseCompleteSettingURL: "After registered, please press the button below to complete the settings",
  openSetting: "Open the settings",
  EnterMaterialKey: "Enter meraki key",
  downloadSampleFile: "Download sample file",
  billing_is_required: "Billing is required",
  agent_not_found: "Agent not found",
  csv_request_failed: "CSV request failed",
  user_registration_failed: "User registration failed",
  tenant_not_found: "Tenant not found. Please confirm the tenant Comapny Name",
  WORK: "WORK",
  PROJECT: "PROJECT",
  COMMUNITY: "COMMUNITY",
  pt: "pt",
  settingMoreDetails: "Setting More Details",
  items: "Items",
  userID: "User ID",
  time: "Time",
  csvLisit: "Column List in CSV",
  fileName: "File Name",
  uploadDate: "Upload Date",
  action: "Action",
  gate: "Gate",
  noMatchSelectall: "No Match (Select All)",
  entryExitflag: "Entry/Exit Flag",
  entryFlag: "Entry Flag",
  exitFlag: "Exit Flag",
  list: "List",
  personInCharge1: "Person In Charge 1",
  personInCharge2: "Person In Charge 2",
  personInCharge3: "Person In Charge 3",
  personInCharge4: "Person In Charge 4",
  personInCharge5: "Person In Charge 5",
  personInCharge6: "Person In Charge 6",
  personInCharge7: "Person In Charge 7",
  personInCharge8: "Person In Charge 8",
  personInCharge9: "Person In Charge 9",
  personInCharge10: "Person In Charge 10",
  clientIntegrationPersonsInCharge1: "Client Integration Person In Charge 1",
  clientIntegrationPersonsInCharge2: "Client Integration Person In Charge 2",
  clientIntegrationPersonsInCharge3: "Client Integration Person In Charge 3",
  clientIntegrationPersonsInCharge4: "Client Integration Person In Charge 4",
  clientIntegrationPersonsInCharge5: "Client Integration Person In Charge 5",
  clientIntegrationPersonsInCharge6: "Client Integration Person In Charge 6",
  clientIntegrationPersonsInCharge7: "Client Integration Person In Charge 7",
  clientIntegrationPersonsInCharge8: "Client Integration Person In Charge 8",
  clientIntegrationPersonsInCharge9: "Client Integration Person In Charge 9",
  clientIntegrationPersonsInCharge10: "Client Integration Person In Charge1 0",
  clientIntegrationPersonsInCharge: "Client Integration Person In Charge",
  clientIntegrationPersonsInCharge_is_required: "Client Integration Person In Charge is required ",
  new_clientIntegration_registered: "Client integration company registered ",
  cannot_select_more_than_3: "Cannot select more than 3",
  email: "Email",
  emails: "Emails",
  new_consultants_registered: "New consultants have been registered",
  personsInCharge: "Persons in charge",
  agent_is_required: "Agent Name is required",
  no_data_available: "No data available",
  client_leader: "Client Leader",
  client_member: "Client Member",
  new_meraki_stack_created: "started to create new Meraki stack",
  setting_updated: "Setting has been updated",
  survey_setting_comment: "Please set the same setting with ",
  select_industry1: "Select Industry1",
  invalid_type: "Invalid type",
  save_progress: "Save Progress",
  client_company_updated: "Client Company has been updated",
  data_solution: "Data Solution",
  filter_condition: "Filter Condition",
  occupation: "Occupation",
  establieshed_year: "Established Year",
  area: "Area",
  do: "Do",
  responded: "Responded",
  no_notification: "No Notification",
  temporary_response_saved: "Temporary response has been saved",
  back_to_list: "Back to List",
  client_id: "Client ID *optional",
  click_white_space: "Click white space to add",
  work_design_survey: "Work Design Survey",
  work_design_pulse_survey: "Work Design Pulse Survey",
  ranking: "ranking",
  project: "project",
  change: "change",
  new_user_registered: "New user has been registered",
  agent_name_is_required: "Agent name is required",
  thousand_yen: "K Yen",
  auth_error_UserNotFoundException: "User does not exist.",
  auth_error_InvalidParameterException: "2 validation errors detected: Value at 'proposedPa…atisfy regular expression pattern: ^[S]+.*[S]+$ ",
  auth_error_NotAuthorizedException: "Incorrect username or password. ",
  auth_error_UserNotConfirmedException: "User is not confirmed.",
  auth_error_LimitExceededException: "Attempt limit exceeded, please try after some time. ",
  auth_error_InvalidPasswordException: "Password did not conform with policy: Password must have symbol characters.",
  auth_system_err: "An unexpected error occurred during login. Please contact your administrator.",
  space_analysis_save_title: "Save space analysis data. ",
  space_analysis_save_message: "Current number of data stored: {{count}}/10",
  space_analysis_beyond_limit_title: "The maximum number of space analysis data storage has been reached. ",
  space_analysis_beyond_limit_message: "Are you sure you want to delete the old data when you press the Save button? <br/>Subject to deletion: {{deleteHistory}}",
  space_analysis_history: "space analysis history",
  simulation_failed: "Simulation failed",
  get_map_failed: "Failed to get map",
  create_err_title: "The registration of the following users has failed",
  create_err_tenant: "client name:{{company_name}}",
  create_err_content: "user name:{{user_name}} email:{{email}}",
  create_err: "There are users for whom the insertion failed. Please close the CSV upload screen and check the message displayed at the bottom of the screen.",
  register_processing_title: "Registration in progress  ",
  register_processing_text: "Please wait while the registration process completes.<br>During the process, do not close the browser or press the back button.",
  register_complete_title: "Registration complete",
  register_complete_text: "The registration process has been completed.<br>For users whose registration has failed, please check your input and try again.",
  serch_user_result_empty: "Can't find user",
  unexcepted_error: "An unexpected error has occurred. If the issue persists after some time, please contact the responsible person.",
  update_user_not_exit: "The user to be updated could not be found.",
  client_not_exit: "The client doesn't exit",
  not_match_err: "The new password and the confirmation password do not match.",
  over_256_err: "Please set the password to 256 characters or fewer.",
  under_6_err: "Please set the password to at least 6 characters.",
  invalid_code_err: "Invalid verification code provided, please try again.",
  temp_pwd_expired_err: "Temporary password has expired and must be reset by an administrator.",
  mail_not_found: "Username/client id combination not found.",
  login_timeout: "session timeout.Please log in again.",
  cant_forget_password: "This account has not yet set an initial password. Please contact the administrator to reset the password.",
  same_as_temp_password_err: "Cannot use temporary password as new password",
};
