import { ApiService } from "./api";

export class SurveyService extends ApiService {
  async postSurveys(type, data) {
    return this.post(`/survey?type=${type}`, data);
  }

  async getSurveys(type) {
    return this.get(`/survey?type=${type}`);
  }

  async getSurveyNotification(tenant) {
    return this.get(`/survey-notification?tenant=${tenant}`);
  }

  async getSingleSurvey(data, title) {
    return this.get(`/survey/${data.survey_id}?type=${title}`);
  }

  async updateSurvey(data) {
    return this.post(`/survey?survey_id=${data.survey_id}&type=${data.type}`, {
      survey: data.survey,
      survey_title: data.survey_title,
    });
  }

  async deleteSurvey(data, title) {
    return this.delete(`/survey/${data}?type=${title}`);
  }

  async postSurveyResponse(params, survey_id, data) {
    return this.post(
      `/survey/${survey_id}/response?${this.serializeParams(params)}`,
      data
    );
  }

  async getSurveyResponsesByUser(data) {
    return this.get(`/survey/responses/${data.tenant}?type=${data.type}`);
  }

  async getSurveyByResponseId(data) {
    return this.get(`/survey/responses/${data.response_id}?type=${data.type}`);
  }

  async getSurveyResponseRate(data) {
    return this.get(`/survey/${data.selSurveyId}/response-rate`, data);
  }

  async updateRemarks(data) {
    const { inputForm, responseRemarks, response_id } = data;
    return this.put(`/survey/remarks/${response_id}`, {
      inputForm,
      responseRemarks,
    });
  }

  async onExportCsvDownload(data) {
    return this.post(`/survey/${data.surveyId}/export`, data, {}, "blob");
  }

  async onExportStatusCsvDownload(data) {
    return this.post(`/survey/status/${data.surveyId}/export`, data, {}, "blob");
  }

  async getWorkshopRemarks(responseId) {
    return this.get(`/survey/remarks/${responseId}`);
  }

  async getSingleSurveyResponses(data, title) {
    return this.get(`/survey/response/${data.survey_id}?type=${title}`);
  }

  async getSurveyNotificationSetting(tenant, survey_type) {
    return this.get(`/survey/notification-setting?tenant=${tenant}&survey_type=${survey_type}`);
  }

  async postSurveyNotificationSetting(setting, tenant, survey_type) {
    return this.post(`/survey/notification-setting?tenant=${tenant}&survey_type=${survey_type}`, setting);
  }

  async checkSurveyResponseReady(data) {
    return this.get(`/survey/response-ready?survey_id=${data.survey_id}&tenant=${data.tenant}&tenant_cognito=${data.tenant_cognito}`);
  }

  async getRecentNotificatedAt(tenantId, surveyId) {
    return this.get(`/survey/recent-notificated-at?tenantId=${tenantId}&surveyId=${surveyId}`);
  }
}
