import React, { useState, useEffect, memo } from "react";
import { ClientService } from "../utils/services/client.service";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { Person as PersonIcon, Public as PublicIcon, SettingsApplications } from "@material-ui/icons";
import Auth from "@aws-amplify/auth";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FaChartPie,
  FaCubes,
  FaRegCompass,
  FaBuffer,
  FaCog,
  FaExternalLinkSquareAlt,
  FaThList,
  FaUserCog,
  FaHandPointRight,
  FaUserTag,
  FaUsers,
  FaHome,
  FaChartLine,
  FaPencilAlt,
} from "react-icons/fa";
import consultant_mgmt from "../assets/img/consultant_mgmt.svg";
import { useStore } from "../../src/Store";
import RoleType from "../utils/constants/RoleType";
import { RegisterService } from "../utils/services/register.service";


const registerService = new RegisterService();
const drawerWidth = 232;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    position: "relative",
  },
  drawerPaper: {
    width: drawerWidth,
    position: "relative",
    display: "flex",
    flexDirection: "unset",
  },
  toolbar: theme.mixins.toolbar,
  textField: {
    width: drawerWidth,
  },
  list: {
    width: 300,
  },
  listItemSelected: {
    backgroundColor: "#ff0000",
  },
}));

export default function Sidebar({ text }) {
  const clientService = new ClientService();
  let location = useLocation();

  const [pathname, setPathName] = useState(location.pathname);
  const classes = useStyles();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const bc = new BroadcastChannel('auth-channel');
  const signOut = () => {
    const idToken = sessionStorage.getItem('idToken');

    Auth.signOut()
      .then((data) => {
        sessionStorage.removeItem('loginTime');
        window.location.href = "/";
      })
      .catch((err) => console.log(err));
    bc.postMessage({ action: 'logout', token: idToken });
  };

  bc.onmessage = async (event) => {
    const { action, token } = event.data;
    if (action === 'logout') {
      const idToken = sessionStorage.getItem('idToken');
      if(idToken == token) {
        Auth.signOut()
        .then((data) => {
          window.location.href = "/";
        })
        .catch((err) => console.log(err));
      }
    }
  };

  const [userType, setUserType] = useState(null);
  const [plan, setPlan] = useState(null);
  const { tenant } = useStore();

  const getCompany = async () => {
    if (tenant) {
      const response_company = await clientService.getCompany(tenant);
      if (response_company) {
        setPlan(response_company.data.plan);
      }
    }
  }

  useEffect(() => {
    Auth.currentUserInfo().then((data) => {
      setUserType(data.attributes["custom:role"]);
    });
  }, []);

  useEffect(() => {
    getCompany();
  }, [tenant]);

  const checkIp = async () => {
    try {
      const response = await clientService.checkIp();
    } catch (error) {
      console.log(error)
      if (error.response && error.response.status === 403) {
        signOut()
      } else {
        signOut()
      }
    }
  }

  useEffect(() => {
    checkIp();
  }, [pathname]);

  if ([RoleType.CLIENT_LEADER, RoleType.CLIENT_MEMBER].includes(userType) && !plan) {
    return null;
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar} />
      <List className={`${i18n.language === "en" ? "" : "root-ja-font "}`}>

        {/* Toppage */}
        {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT, RoleType.CLIENT_LEADER].includes(userType) && plan !== "free_survey" && (
          <ListItem
            button
            className="ps-2 main-menu d-flex align-items-center"
            selected={location && location.pathname === "/"}
            onClick={() => {
              navigate("/");
            }}
            key="consultant"
          >
            <ListItemIcon>
              <FaHome size={20} />
            </ListItemIcon>
            <p className=" mb-0 ms-3 main-menu-headline" >{t("top_page")}</p>
          </ListItem>
        )}

        {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT].includes(userType) && plan !== "free_survey" && (
          <>
            {/* Management */}
            <div className="ps-2 main-menu d-flex align-items-center">
              <ListItemIcon>
                <img src={consultant_mgmt} className="sidebar-icons" />
              </ListItemIcon>
              <p className=" mb-0 ms-3 main-menu-headline" >{t("management")}</p>
            </div>

            {/* Account Management　 */}
            <ListItem
              button
              className="border-bottom  "
              selected={location && location.pathname === "/account-management"}
              onClick={() => {
                navigate("/account-management");
              }}
              key="consultant"
            >
              <ListItemText
                primary={t("account_management")}
                className="ms-4 ps-1 "
              />
            </ListItem>

            {/* Research Management */}
            {[RoleType.ADMIN].includes(userType) && (
              < ListItem
                button
                className="border-bottom"
                selected={
                  location && location.pathname === "/research-management"
                }
                onClick={() => {
                  navigate("/research-management");
                }}
                key="research"
              >
                <ListItemText
                  primary={t("research_management")}
                  className="ms-4 ps-1"
                />
              </ListItem>
            )
            }
          </>
        )}

        {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT, RoleType.CLIENT_LEADER].includes(userType) && (
          <>
            {/* As Is */}
            <div className="ps-2 main-menu d-flex align-items-center">
              <ListItemIcon>
                <FaChartPie size={20} />
              </ListItemIcon>
              <p className=" mb-0 ms-3 main-menu-headline">{t("as_is")}</p>
            </div>

            {/* Operation Analysis */}
            {plan !== "free_survey" && plan !== "light" && (
              <ListItem
                className="border-bottom"
                key="operation"
                button
                selected={location && location.pathname === "/operation-analysis"}
                onClick={() => {
                  navigate("/operation-analysis");
                }}
              >
                <ListItemText primary={t("Operation")} className="ms-4 ps-1" />
              </ListItem>
            )
            }

            {/* Room Analysis */}
            {plan !== "free_survey" && plan !== "light" && (
              <ListItem
                className="border-bottom"
                key="space"
                button
                selected={location && location.pathname === "/space-analysis"}
                onClick={() => {
                  navigate("/space-analysis");
                }}
              >
                <ListItemText primary={t("Space")} className="ms-4 ps-1" />
              </ListItem>
            )
            }

            {/* Survey Results */}
            <ListItem
              className="border-bottom"
              button
              selected={location && location.pathname === "/survey-results"}
              onClick={() => {
                navigate("/survey-results");
              }}
              key="survey-results"
            >
              <ListItemText
                primary={t("survey_results")}
                className="ms-4 ps-1"
              />
            </ListItem>
          </>
        )}

        {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT, RoleType.CLIENT_LEADER, RoleType.CLIENT_MEMBER].includes(userType) && (
          <>
            {/* Answer Survey */}
            < ListItem
              className="border-bottom"
              button
              selected={location && location.pathname === "/survey-list"}
              onClick={() => {
                navigate("/survey-list");
              }}
              key="list"
            >
              <ListItemText primary={t("answer_survey")} className="ms-4 ps-1" />
            </ListItem>
          </>
        )}

        {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT, RoleType.CLIENT_LEADER].includes(userType) && plan !== "free_survey" && plan !== "light" && (
          <>
            {/* Simulation */}
            <div className="ps-2 main-menu d-flex align-items-center">
              <ListItemIcon>
                <FaChartLine size={20} />
              </ListItemIcon>
              <p className=" mb-0 ms-3 main-menu-headline">{t("Simulation")}</p>
            </div>

            {/* Room Simulation */}
            <ListItem
              className="border-bottom"
              key="location"
              button
              selected={location && location.pathname === "/room-simulation"}
              onClick={() => {
                navigate("/room-simulation");
              }}
            >
              <ListItemText primary={t("room_simulation")} className="ms-4 ps-1"
              />
            </ListItem>
            {/* Cost Simulation */}
            <ListItem
              button
              key="cost"
              className="border-bottom"
              selected={location && location.pathname === "/cost-simulation"}
              onClick={() => {
                navigate("/cost-simulation");
              }}
            >
              <ListItemText primary={t("CostSimulation")} className="ms-4 ps-1" />
            </ListItem>

            {/* Commute Simulation */}
            <ListItem
              className="border-bottom"
              button
              selected={location && location.pathname === "/commute-simulation"}
              onClick={() => {
                navigate("/commute-simulation");
              }}
              key="w-setting"
            >
              <ListItemText primary={t("commute_simulation")} className="ms-4 ps-1" />
            </ListItem>

            {/* Comparative Review */}
            {[RoleType.ADMIN].includes(userType) && (
              <ListItem
                className="border-bottom"
                button
                selected={
                  location && location.pathname === "/comparative-review"
                }
                onClick={() => {
                  navigate("/comparative-review");
                }}
                key="w-setting"
              >
                <ListItemText primary={t("comparative_review")} className="ms-4 ps-1" />
              </ListItem>
            )}
          </>
        )}

        {[RoleType.ADMIN].includes(userType) && plan !== "free_survey" && plan !== "light" && (
          <>
            {/* Qualitative Research */}
            <div className="ps-2 main-menu d-flex align-items-center">
              <ListItemIcon>
                <FaPencilAlt size={20} />
              </ListItemIcon>
              <p className=" mb-0 ms-3 main-menu-headline">{t("qualitative_research")}</p>
            </div>

            {/* Workshop */}
            <ListItem
              className="border-bottom"
              button
              selected={location && location.pathname === "/workshop-results"}
              onClick={() => {
                navigate("/workshop-results");
              }}
              key="workshop-results"
            >
              <ListItemText primary={t("workshop_results")} className="ms-4 ps-1" />
            </ListItem>

            {/* Top Interview */}
            <ListItem
              button
              className="border-bottom"
              selected={location && location.pathname === "/list-top-interview"}
              onClick={() => {
                navigate("/list-top-interview");
              }}
              key="top-interview-list"
            >
              <ListItemText primary={t("top_interview")} className="ms-4 ps-1" />
            </ListItem>
          </>
        )}

        {[RoleType.ADMIN].includes(userType) && (
          <>

            <div className="ps-2 main-menu d-flex align-items-center">
              <ListItemIcon>
                <FaPencilAlt size={20} />
              </ListItemIcon>
              <p className=" mb-0 ms-3 main-menu-headline">{t("data_solution")}</p>
            </div>


            <ListItem
              className="border-bottom"
              button
              selected={location && location.pathname === "/datasolution/operation-analysis"}
              onClick={() => {
                navigate("/datasolution/operation-analysis");
              }}
              key="datasolution/operation-analysis"
            >
              <ListItemText primary={t("Operation")} className="ms-4 ps-1" />
            </ListItem>


            <ListItem
              className="border-bottom"
              button
              selected={location && location.pathname === "/datasolution/space-analysis"}
              onClick={() => {
                navigate("/datasolution/space-analysis");
              }}
              key="datasolution/space-analysis"
            >
              <ListItemText primary={t("Space")} className="ms-4 ps-1" />
            </ListItem>


            <ListItem
              className="border-bottom"
              button
              selected={location && location.pathname === "/datasolution/place-style-survey"}
              onClick={() => {
                navigate("/datasolution/place-style-survey");
              }}
              key="datasolution/place-style-survey"
            >
              <ListItemText primary={t("place_style_survey")} className="ms-4 ps-1" />
            </ListItem>

            <ListItem
              className="border-bottom"
              button
              selected={location && location.pathname === "/datasolution/work-design-survey"}
              onClick={() => {
                navigate("/datasolution/work-design-survey");
              }}
              key="datasolution/work-design-survey"
            >
              <ListItemText primary={t("work_design_survey")} className="ms-4 ps-1" />
            </ListItem>

            <ListItem
              className="border-bottom"
              button
              selected={location && location.pathname === "/datasolution/work-design-pulse-survey"}
              onClick={() => {
                navigate("/datasolution/work-design-pulse-survey");
              }}
              key="datasolution/work-design-pulse-survey"
            >
              <ListItemText primary={t("work_design_pulse_survey")} className="ms-4 ps-1" />
            </ListItem>

            <ListItem
              className="border-bottom"
              button
              selected={location && location.pathname === "/datasolution/ranking"}
              onClick={() => {
                navigate("/datasolution/ranking");
              }}
              key="datasolution/ranking"
            >
              <ListItemText primary={t("ranking")} className="ms-4 ps-1" />
            </ListItem>

            <ListItem
              className="border-bottom"
              button
              selected={location && location.pathname === "/datasolution/project"}
              onClick={() => {
                navigate("/datasolution/project");
              }}
              key="datasolution/project"
            >
              <ListItemText primary={t("project")} className="ms-4 ps-1" />
            </ListItem>
          </>
        )}

        {[RoleType.ADMIN, RoleType.AGENT, RoleType.CONSULTANT, RoleType.CLIENT_LEADER].includes(userType) && plan !== "free_survey" && (
          <>
            {/* Office Sample */}
            <ListItem
              button
              key="officeSample"
              className="border-bottom p-0 w-100"
              onClick={() => {
                window.location.href = 'https://vis-produce.com/projects/branding/workplace_design/'
              }}
            >
              <div className="position-relative w-100">
                <img src={require("../assets/img/office_image.png")} alt="" className="w-100" />
                <h6 className="btn btn-EAC21D-office-sample op-100 office-sample-btn">{t("check_office_sample")}</h6>
              </div>
            </ListItem>
          </>
        )}

        <ListItem key="logout" className="mb-2" >
          <ListItemText
            primary={
              <Button variant="contained" onClick={signOut} fullWidth style={{ background: '#44403f', color: '#fff', padding: "px 16px", left: "15px" }}>
                {t("logout")}
              </Button>
            }
          />
        </ListItem>
      </List>
    </Drawer >
  );
}
